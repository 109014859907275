<template>
    <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
    <div class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 90%; min-height: 50%;">
      <div class="relative">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              Update assignee
            </div>
          </div>
          <div class="p-4">
          <div class="mb-4">
                <MultiSelect 
                :inputext="employeeSearchText" 
                :inputId="'requestassignemp'"
                :lableText="'Assign to'"
                :placholderText="`Select Employee`"
                :autoFocus="false"
                :showArrow="true"
                :setReadOnly="false"
                :searchCallApi="false"
                :showPlus="false"
                :keyName="'fullName'"
                :idKey="'userDetailId'"
                :sectionId="'requesectionlistid'"
                :listId="'empreqlistid'"
                :items="employeeList"
                :selectedValues="selectedEmployee"
                @selectNewForVal="selectEmployee"
                />
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                <Button :disabled="timeErr" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveAssesmentDetail()"/>
            </div>
            </div>
        </div>
    </div>
    <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" />
  </div>
</template>
<script>
// import ConfirmBox from '@/View/components/ConfirmBox.vue'
// import StarRating from 'vue-star-rating'
// import TextField from '@/View/components/textfield.vue'
// import NumberInput from '@/View/components/number-text-field.vue'
// import TextAreaInput from '@/View/components/textAreaInput.vue'
// import DateTimePickerCustom from '@/View/components/dateTimepicker'
// import TimePicker from '../../job/components/timePicker.vue'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
import moment from 'moment'
export default {
  name: 'addupdateshcedule',
  components: {
    Button,
    MultiSelect,
    // TextAreaInput,
    // ConfirmBox,
    // DateTimePickerCustom,
    // TimePicker,
    // TextField,
    // StarRating
  },
  data () {
    return {
      removeConfirm: false,
      employeeList: [],
      selectedEmployee: [],
        employeeSearchText: '',
        instructions: '',
        selectedDate: '',
        startTime: '',
        endTime: '',
        isUnschedule: true,
        isAnyTime: false,
        timeErr: false,
        startDateTime: '',
        endDateTime: '',
        logedInUser: 0,
        requestDetailId: 0,
    }
  },
  props: ['assementObj', 'selectedTeam', 'requestId', 'requetsObj'],
   created() {
  },
  mounted () {
    console.log('requetsObj', this.requetsObj)
    this.$root.$on('confirmBoxHandler', (response) => {
      if (response) {
        this.deleteApi()
      }
      document.body.style = 'overflow: visible;'
      this.removeConfirm = false
    })
    this.requestDetailId = this.requestId
    console.log('vvvvvvvvvvvvv', JSON.parse(localStorage.getItem('jobUserInfo')));
    let detailObj = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.logedInUser = detailObj.userDetailId
    document.body.style = 'overflow: hidden;'
    console.log('assementObj', this.assementObj);
    this.instructions = this.assementObj.instructions
    if (this.assementObj.startDateTime == '' && this.assementObj.endDateTime === '') {
      this.isUnschedule = true
    } else {
      this.isUnschedule = this.assementObj.isUnschedule
    }
    this.isAnyTime = this.assementObj.isAnyTime
    this.startDateTime = this.assementObj.startDateTime
    this.endDateTime = this.assementObj.endDateTime
    
    if (this.assementObj.startDateTime !== '') {
      this.setTimes(this.assementObj.startDateTime,this.assementObj.endDateTime)
      this.selectedDate = moment(new Date(this.assementObj.startDateTime)).format('YYYY-MM-DD')
    } else {
      this.selectedDate = moment(new Date()).format('YYYY-MM-DD')
    }

    if (this.selectedTeam.length > 0) {
      this.selectedEmployee = []
        this.selectedTeam.map(el => {
          this.selectedEmployee.push({...el, fullName: el.firstName + ' ' + el.lastName})
      })  
    }
    this.getEmployeeList()
  },
  watch: {
    startTime: {
      handler () {
        this.timeErr = false
           let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.updateDate(this.startTime,this.endTime)
      }
    },
    endTime: {
      handler () {
        this.timeErr = false
        let x = this.startTime.split(':')
        let hour = parseInt(x[0])
        let min = parseInt(x[1])
        let y = this.endTime.split(':')
        let hr2 = parseInt(y[0])
        let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
        this.updateDate(this.startTime,this.endTime)
      }
    },
    isAnyTime: {
      handler () {
        if (this.isAnyTime) {
          let temp = moment(this.selectedDate).format('YYYY-MM-DD')
          console.log('temp', temp);
          let strDate = temp + ' ' + '00:00'
          let endDate = temp + ' ' + '23:59'
          console.log('strDate', strDate);
          console.log('endDate', endDate);
          this.startDateTime = new Date(strDate).toUTCString()
          this.endDateTime = new Date(endDate).toUTCString()
        } else {
          this.scheduleAsses()
        }
      }
    },
  },
  methods: {
    selectEmployee (emplist) {
      this.selectedEmployee = []
      let tempEmpList = []
      emplist.map((data) => {
        // let obj = {
        //   userDetailId: data.userDetailId,
        //   fullName: data.fullName
        // }
        tempEmpList.push({userDetailId:data.userDetailId})
        this.selectedEmployee.push(data)
      })
    },
     setTimes (start, end) {
      let hours = new Date(start).getHours()
      let min = new Date(start).getMinutes() 
      this.startTime = hours + ':' + min
      let hours2 = new Date(end).getHours()
      let min2 = new Date(end).getMinutes()
      this.endTime = hours2 + ':' + min2
    },
    deleteApi () {
      MyJobApp.DeleteReqAssessment(
        this.requestDetailId,
        response => {
          this.$root.$emit('closeScheduleHandler', true, response)
          document.body.style = 'overflow: visible;'
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    unSchedule () {
      this.isUnschedule = true
      this.startDateTime = ''
      this.endDateTime = ''
    },
    scheduleAsses () {
      this.isUnschedule = false
      let hours = new Date().getHours()
      let min = new Date().getMinutes()
      hours = min > 45 &&  min < 60 ? hours + 1 : hours
      let minutes = min <= 15 ? 15 : min <= 30 ? 30 : min <= 45 ? 45 : min <= 60 ? 0 : 0
      this.startTime = hours + ':' + minutes
      this.endTime = hours + 1 + ':' + minutes
      // console.log(startTime);
      // console.log(endTime);
      this.updateDate(this.startTime,this.endTime)
    },
    updateDateValue () {
      this.updateDate(this.startTime,this.endTime)
    },
    updateDate (startTime,endTime) {
      let strDate = moment(new Date(this.selectedDate)).format('YYYY-MM-DD') + ' ' + startTime
      let endDate = moment(new Date(this.selectedDate)).format('YYYY-MM-DD') + ' ' + endTime
      this.startDateTime = new Date(strDate).toUTCString(),
      console.log('startDateTime', this.startDateTime);
      this.endDateTime = new Date(endDate).toUTCString()
      console.log('endDateTime', this.endDateTime);
    },
    saveAssesmentDetail () {
      document.body.style = 'overflow: auto;'
      console.log('this.requestDetailId', this.requestDetailId)
      if (parseInt(this.requestDetailId) > 0) {
        let userList = []
        this.selectedEmployee.map(user => {
          let obj = {
            userDetailId: user.userDetailId
          }
          userList.push(obj)
        })
        let obj = {
          requestId: this.requestDetailId,
          assignToList: userList
        }
        this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
        MyJobApp.updateRquestAssignee(
          obj,
          (response) => {
            console.log('response', response)
            this.$root.$emit('closeScheduleHandler', true, null)
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
      } else {
        let data = {
          instructions : this.instructions,
          isUnschedule : this.isUnschedule,
          isAnyTime : this.isAnyTime,
          startDateTime: this.startDateTime,
          endDateTime: this.endDateTime,
          selectedTeam: this.selectedEmployee,
          isSchedule: true,
        }
        this.$root.$emit('closeScheduleHandler', true, data)
      }
      
      
    },
    discard () {
      document.body.style = 'overflow: auto;'
      this.$root.$emit('closeScheduleHandler', false, null)
    },
    getEmployeeList () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map(el => {
               tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
               if (this.logedInUser === el.userDetailId && this.selectedEmployee.length === 0) {
              this.selectedEmployee.push({...el, fullName: el.firstName + ' ' + el.lastName})
            }
            })
            
          }
          this.employeeList = tempList
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  },
  beforeDestroy () {
    this.$root.$off("confirmBoxHandler");
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>

</style>