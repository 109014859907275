import {getCookies} from '@/utils/cookies'
import axios from 'axios'
import data from '../assets/countryList.json'
import store from '@/store'
import * as config from '../config'
export function getEmployeeProfileImageUrl (employeeId, size) {
  return config.API_ROOT + '/api/v1/hrm/employee/getEmployeeProfile?empId=' + encodeURIComponent(employeeId) + '&authToken=' + encodeURIComponent(getCookies('authToken')) + '&size=' + size
  // '&time=' + encodeURIComponent(new Date().getTime().toString()) +
}
export function getContactProfileImageUrl (contactId, size) {
  return config.API_ROOT + '/api/v1/crm/contact/getProfile?contactId=' + encodeURIComponent(contactId) + '&authToken=' + encodeURIComponent(getCookies('authToken')) + '&size=' + size + '?' + new Date().getTime()
}
export function getCustomerProfileImageUrl (customerAccountId, size) {
  return config.API_ROOT + '/api/v1/crm/customerAccount/getProfile?customerAccountId=' + encodeURIComponent(customerAccountId) + '&authToken=' + encodeURIComponent(getCookies('authToken')) + '&size=' + size
}

export function getLoginEmployeeProfileImageUrl (employeeId, size) {
  return config.API_ROOT + '/api/v1/hrm/employee/getLoginEmployeeProfile?authToken=' + encodeURIComponent(getCookies('authToken')) + '&size=' + size
  // '&time=' + encodeURIComponent(new Date().getTime().toString()) + '&size=' +
}
export function getCompanyLogoImageURL (size) {
  return config.API_ROOT + '/api/v1/systemSetting/getCompanyLogo?authToken=' + encodeURIComponent(getCookies('authToken')) + '&size=' + size
}
export function getLoginCompanyLogoImageURL (domain, size) {
  return config.API_ROOT + '/api/v1/auth/getCompanyLogo?domain=' + domain + '&size=' + size
}
export function checkForBrowser () {
  // return
  // var ua = navigator.userAgent

  // var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
  //   if (/OPR|Opera|SamsungBrowser|MSIE|Trident|Seamonkey|Firefox/i.test(ua)) {
  //     setCookiedFOrAlertAboutBrowser()
  //   }
  // } else if (!isChrome) {
  //   setCookiedFOrAlertAboutBrowser()
  // }
}

// function setCookiedFOrAlertAboutBrowser () {
//   if (getCookies('alertForBrowserOtherThenChrome') === null) {
//     setCookiesWithExpire('alertForBrowserOtherThenChrome', 'browserIsNotChrome', 1)
//     alert('This site works with current chrome version only')
//   }
// }

export function getFileSize (bytes) {
  // var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  // if (bytes === 0) return '0 Byte'
  // var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  // return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  if (bytes === 0) return '0 Bytes'
  var decimals = 2
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function GetHoursMinuteAndSeconds (totalSeconds) {
  return `${Math.round(totalSeconds / 3600) >= 1 ? Math.round(totalSeconds / 3600) + ' hour, ' : ''} ${Math.round(totalSeconds / 3600) >= 1 ? Math.round((totalSeconds % 3600) / 60) + ' minutes, ' : (totalSeconds / 60) >= 1 ? Math.round((totalSeconds / (60))) + ' minutes, ' : ''} ${(totalSeconds / 60) >= 1 ? (totalSeconds % 60) : totalSeconds} seconds`
}

export function uploadFileInTempWithThumb (file, id, url, formData, source, data1) {
  // setting current time to calc remaining time
  let timeStarted = new Date().getTime()
  formData.append('authToken', getCookies('authToken'))
  formData.append('file', file)
  // getting response ofthe request
  return axios.post(url,
    formData,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      cancelToken: source.token,
      onUploadProgress: function (progressEvent) {
        if (document.getElementById(id) !== null) {
          let progressString = ''
          let progress = (progressEvent.loaded) / progressEvent.total
          let percentageProgress = Math.round(progress * 100)
          // For multiple file upload. Getting instance of obj
          // if null means singular upload file else multiple upload process
          if (data1 !== null) {
            data1.progressLinearValue = percentageProgress
          } else {
            store.dispatch('SetUploadProgressValue', percentageProgress)
          }
          // document.getElementById(id + '0').value = Math.round(progress * 100)
          progressString += `${percentageProgress}% of ${getFileSize(progressEvent.total)}, ${percentageProgress === 100 ? 'Please wait' : GetHoursMinuteAndSeconds(Math.round((((new Date().getTime() - timeStarted) / progress) - (new Date().getTime() - timeStarted)) / 1000)) + ' remaining'} `
          document.getElementById(id).innerHTML = progressString
        }
      }
    }
  )
}

export function downloadFile (formData, url, source) {
  // setting current time to calc remaining time
  let timeStarted = new Date().getTime()
  // getting response ofthe request
  console.log('Source Token', source.token)
  return axios.post(url,
    formData,
    {
      Accept: 'application/json',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob',
      cancelToken: source.token,
      onDownloadProgress: function (progressEvent) {
        let progress = (progressEvent.loaded) / progressEvent.total
        let percentageProgress = Math.round(progress * 100)

        store.dispatch('SetUploadProgressValue', percentageProgress)
        store.dispatch('SetDownloadMessage', `${percentageProgress}% of ${getFileSize(progressEvent.total)}, ${percentageProgress === 100 ? 'Please wait' : GetHoursMinuteAndSeconds(Math.round((((new Date().getTime() - timeStarted) / progress) - (new Date().getTime() - timeStarted)) / 1000)) + ' remaining'} `)
      }
    }
  )
}
export function getCountryPhoneCodeList (size) {
  let countryList = []
  for (let i = 0; i < data.length; i++) {
    data[i].searchString = data[i].dialCode + ' ' + data[i].name
    data[i].flag = `https://www.countryflags.io/${data[i].code}/flat/${size}.png`
    countryList.push(data[i])
  }
  // console.log(JSON.stringify(countryList))
  return [...countryList]
}

export function getMonthName (number, date) {
  let monthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  if (date !== '') {
    let nDt = new Date(date)
    number = nDt.getMonth()
    return monthList[number]
  } else {
    return monthList[number - 1]
  }
}

export function getWeekName (number, date) {
  let dayList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  if (date !== '') {
    let nDt = new Date(date)
    number = nDt.getDay()
  }
  return dayList[number]
}
export function getWeekNumber (date) {
  date = new Date(date)
  let weekDay = Math.ceil(date.getDate() / 7)
  return weekDay
}

export function checkFileTypeForPreview (fileType) {
  if (fileType.indexOf('png') >= 0 || fileType.indexOf('jpg') >= 0 || fileType.indexOf('jpeg') >= 0 || fileType.indexOf('gif') >= 0 || fileType.indexOf('svg') >= 0 || fileType.indexOf('pdf') >= 0 || fileType.indexOf('mp4') >= 0) {
    return true
  }
  return false
}
export function checkFileExtensionForPreview (type) {
  var fileArray = type.split('.')
  if (fileArray.length > 1) {
    type = fileArray[fileArray.length - 1]
  }
  type = type.toLowerCase()
  if (type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'gif' || type === 'svg' || type === 'pdf' || type === 'mp4') {
    return true
  }
  return false
}
export function downloadMp3File (url, total) {
  store.dispatch('SetLoader', {status: true, message: 'Downloading....'})
  let timeStarted = new Date().getTime()
  return axios.get(url,
    {
      Accept: 'application/json',
      headers: {
        'Content-Type': 'audio/mpeg'
      },
      responseType: 'blob',
      onDownloadProgress: function (progressEvent) {
        store.dispatch('SetLoader', {status: false, message: ''})
        let progress = (progressEvent.loaded) / total
        let percentageProgress = Math.round(progress * 100)
        store.dispatch('SetDownloadMessage', `${percentageProgress}% of ${getFileSize(progressEvent.loaded)}, ${percentageProgress === 100 ? 'Completed' : GetHoursMinuteAndSeconds(Math.round((((new Date().getTime() - timeStarted) / progress) - (new Date().getTime() - timeStarted)) / 1000)) + ' remaining'} `)
        store.dispatch('SetUploadProgressValue', percentageProgress)
        if (percentageProgress === 100) {
          store.dispatch('SetUploadProgressValue', 0)
          store.dispatch('SetDownloadMessage', '')
        }
      }
    }
  )
}