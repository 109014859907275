<template>
  <div class="">
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="quote_name_col w-32 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            </div>
            <div class="quote_name_col w-48 uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            </div>
          </div>
          <div v-if="quotesList.length > 0">
            <div v-for="(data, index) in quotesList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="goToDetail(data.quotationMasterId)">
              <div class="quote_name_col w-32">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6"># {{data.quotationNumber}}</span>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                <span class="text-gray4 heading-6">{{data.grandTotal | amountOnlyNumber}}</span>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                <span class="text-gray4 heading-6">{{data.quoteDate | formatDataeTime}}</span>
                </div>
              </div>
              <div class="quote_name_col w-48">
                <div class="flex items-center">
                <span :style="`color: ${data.statusColor};`" class=" text-gray4 heading-6">{{data.statusName}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
        </div>
      </div>
     </div>
</template>
<script>
// import Button from '@/View/components/globalButton.vue'
// import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
// import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
export default {
  name: "quotation",
  components: {
    NoRecords,
    // descIconSvg,
    // ascIconSvg,
    // Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Quotation #', icon: '', sortName: 'quotationNumber'},
        {title: 'Grand Total', icon: '', sortName: 'grandTotal'},
        {title: 'Quote Date', icon: 'DESC', sortName: 'quoteDate'},
        {title: 'Status', icon: '', sortName: 'statusName'}
      ],
    };
  },
  props: ['quotesList'],
  created() {},
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    addNewQuote () {
      this.$router.push({name: 'CreateQuote', params: {quoteId: 0}, query: {customerId: this.customerRedirectId}})
    },
    goToDetail (Id) {
      this.$router.push({name: 'quoteDetail', params: {quoteId: Id}})
    }
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>