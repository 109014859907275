<template>
  <div>
    <div class="mb-4 sm:mb-0 col-span-2">
            <div v-if="showAddSelectAddr" class="" >
              <div>
                <div v-for="(add,ind) in addressList" :key="ind">
                  <div @click="selectJobAddress(add, ind)" class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center justify-between">
                      <div class="flex gap-1">
                        <p v-if="!add.selected" class="text-gray3">
                        <i class="fa-regular fa-circle h-5 w-5"></i>
                      </p>
                      <span v-else class="text-primary">
                        <i class="fa-solid fa-circle-check h-5 w-5"></i>
                      </span>
                      <span class=" font-semibold">
                        {{add.addressTitle === '' ? 'Other' : add.addressTitle}}
                      </span>
                      </div>
                      <p class="text-error heading-7">{{add.displayDistance}}</p>
                    </div>
                    <div>
                      <p>{{add.fullAddress}}</p>
                    </div>
                  </div>
                </div>  
              </div>
              <p v-if="errObj.addressErr !== ''" class="text-error heading-7">
                {{ errObj.addressErr }}
              </p>
            </div>
            <div class=" col-span-2">
              <div @click="checkboxCheck()" class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center">
                      <div class="flex gap-1">
                        <p v-if="!isOfficeAddressSel" class="text-gray3">
                          <i class="fa-regular fa-circle h-5 w-5"></i>
                        </p>
                        <span v-else class="text-primary">
                          <i class="fa-solid fa-circle-check h-5 w-5"></i>
                        </span>
                      <span class=" font-semibold">Office Location
                      </span>
                      </div>
                    </div>
                  </div>
            </div>
            <div class=" col-span-2">
              <div @click="addNewAddress()" class="cursor-pointer hover:bg-screen_bg mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center justify-between">
                      <div class="flex gap-1">
                        <p v-if="!isAddedNewAddress" class="text-gray3 mb-0">
                          <i class="fa-regular fa-circle h-5 w-5"></i>
                        </p>
                        <span v-else class="text-primary">
                          <i class="fa-solid fa-circle-check h-5 w-5"></i>
                        </span>
                      <span class=" font-semibold">Other Location
                      </span>
                      </div>
                      <div>
                          <p class="text-error heading-7" v-if="isAddedNewAddress">{{otherLocationDistance}}</p>
                      </div>
                    </div>
                    <div v-if="isAddedNewAddress" class="">
                      {{AddressObj.fullAddress}}
                    <div v-if="isNewForCustomer" class="flex items-center">
                      <CheckBoxInput :checkValue="AddressObj.isAddressAddToCustomer" :label="``" :size="'small'" :disabled="false" @setIsActive="addAddressInCustomer"/>
                      <span
                        class="pl-0 text-text2 cursor-pointer"
                        @click.stop.prevent="addAddressInCustomer"
                        >Add address in customer file</span
                      >
                    </div>
                    </div>
                  </div>
            </div>
            </div>
             <ReqAddressAddUpdate
                :addressList="addressList"
                :AddressObj="AddressObj"
                v-if="addOther"
                />
  </div>
</template>
<script>
import CheckBoxInput from '../../components/checkBoxInput.vue';
import timeZoneWithCountryCode from '@/assets/timeZoneWithCountryCode.json'
import milesCountry from '@/assets/milesCountry.json'
import MyJobApp from '@/api/MyJobApp.js'
import ReqAddressAddUpdate from './ReqAddressAddUpdate.vue';
export default {
  name: "addressSelection",
  components: {
    CheckBoxInput,
    ReqAddressAddUpdate,
  },
  props: ['customerId', 'lat','long', 'selectedAdrObj'],
  data() {
    return {
      errObj: {
        addressErr: "",
      },
      AddressObj: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        latitude: 0,
        longitude: 0,
        geofenceRangeDiameter: 0,
        fullAddress: "",
        houseNo: "",
        floorNumber: "",
        buildingName: "",
        directionNote: "",
        contactNumber: "",
        contactName: "",
        isAddressAddToCustomer: false,
        addressTitle: '',
      },
      addedAddress: null,
      isAddedNewAddress: false,
      addNewjobAdd: false,
      isOfficeAddressSel: false,
      showAddSelectAddr: false,
      isDisable: false,
      addressList: [],
      LoginUserlatLong: null,
      OfficelatLong: null,
      displayDistance: '',
      otherLocationDistance: '',
      addOther: false,
      custDetail: null,
      isNewForCustomer: false,
    };
  },
  created() {
  },
  computed: {},
  watch: {
    customerId: {
      handler() {
        if (this.customerId > 0) {
          this.getCustDetails(this.customerId)
        }
      }
    }
  },
  mounted() {
    this.getCustDetails(this.customerId)
    this.LoginUserlatLong = JSON.parse(localStorage.getItem('LoginUserlatLong'))
    this.OfficelatLong = JSON.parse(localStorage.getItem('orgInfo'))
    this.$root.$on("ReqaddreHandler", (isOpen, data, isNew) => {
        console.log('createjobAddressHandler', isNew);
        
      if (isOpen && data !== null && this.addOther) {
        this.setAddress(data, false);
        this.isAddedNewAddress = true
         this.isOfficeAddressSel = false
        let count = 0
          this.addressList.forEach(e => {
            e.selected = false
            if (e.latitude === data.latitude && e.longitude === data.longitude) {
              count += 1
            }
          })
          if (count === 0) {
            this.isNewForCustomer = true;
          }
      }
      
      this.addOther = false
    });
  },
  beforeDestroy() {
    this.$root.$off("setNewTabActive");
    this.$root.$off("updateCustHandler");
    // this.$root.$off("addEditCheckList");
    this.$root.$off("addCustomerHandler");
    this.$root.$off("setItemSelectedHandler");
    this.$root.$off("addAddressHandler");
    this.$root.$off("finalvisitlistHandler");
    this.$root.$off("setSelectedEmpHandler");
    this.$root.$off("createjobAddressHandler");
    this.$root.$off("EditAddressHandler");
  },
  methods: {
    addAddressInCustomer() {
      this.AddressObj.isAddressAddToCustomer =  !this.AddressObj.isAddressAddToCustomer
      this.$emit('setSelectedAddress', this.AddressObj)
    },
    addNewAddress() {
      this.addOther = true;
    },
    setAddress(data) {
        let temp = 0
        let temp3 = ''
        if (this.LoginUserlatLong !== null && data) {
            temp = this.calcDistance(this.LoginUserlatLong.latitude, this.LoginUserlatLong.longitude, data.latitude, data.longitude)  
            temp3 = ` from user`
        } else if (data) {
            temp = this.calcDistance(this.OfficelatLong.latitude, this.OfficelatLong.longitude, data.latitude, data.longitude)
            temp3 = ` from office`
        }
        let value = this.getDistanceUnit(temp * 1000 )
        this.otherLocationDistance = value  + temp3

      this.jobDisplayAdd = data !== null ? data.fullAddress : "";
      this.AddressObj.addressTitle = data !== null ? data.addressTitle : "";
      this.AddressObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.AddressObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.AddressObj.city = data !== null ? data.city : "";
      this.AddressObj.state = data !== null ? data.state : "";
      this.AddressObj.zip = data !== null ? data.zip : "";
      this.AddressObj.latitude = data !== null ? data.latitude : 0;
      this.AddressObj.longitude = data !== null ? data.longitude : 0;
      this.AddressObj.country = data !== null ? data.country : "";
      this.AddressObj.geofenceRangeDiameter =
        data !== null ? data.selectedRadius : "";
      this.AddressObj.fullAddress = data !== null ? data.fullAddress : "";
      this.AddressObj.houseNo = data !== null ? data.houseNo : "";
      this.AddressObj.floorNumber = data !== null ? data.floorNumber : "";
      this.AddressObj.buildingName = data !== null ? data.buildingName : "";
      this.AddressObj.directionNote = data !== null ? data.directionNote : "";
      this.AddressObj.contactNumber = data !== null ? data.contactNumber : "";
      this.AddressObj.contactName = data !== null ? data.contactName : "";

      


      if (this.LoginUserlatLong !== null && data) {
        let dist = this.calcDistance(this.LoginUserlatLong.latitude, this.LoginUserlatLong.longitude, data.latitude, data.longitude)  
        let final = this.getDistanceUnit(dist * 1000 )
        this.displayDistance = final + ' from user'
      } else if (this.OfficelatLong !== null) {
        let dist = this.calcDistance(this.AddressObj.latitude,this.AddressObj.longitude, this.OfficelatLong.latitude, this.OfficelatLong.longitude)
        let final = this.getDistanceUnit(dist * 1000 )
        final +=  ' km from Office'
         this.displayDistance = final + ' from Office'
      }

        this.$emit('setSelectedAddress', this.AddressObj)

    },
    selectJobAddress(data, index) {
      this.isOfficeAddressSel = false
      this.addressList.forEach((e,i) => {
        if (index !== i) {
          e.selected = false
        } else {
          e.selected = true
        }
      });
      this.isAddedNewAddress = false;
      this.addedAddress = null
      this.AddressObj.isAddressAddToCustomer = false;
      this.jobDisplayAdd = data !== null ? data.addressText : "";
      this.AddressObj.addressTitle = data !== null ? data.addressTitle : "";
      this.AddressObj.fullAddress = data !== null ? data.fullAddress : "";
      this.AddressObj.addressLine1 = data !== null ? data.addressLine1 : "";
      this.AddressObj.addressLine2 = data !== null ? data.addressLine2 : "";
      this.AddressObj.city = data !== null ? data.city : "";
      this.AddressObj.state = data !== null ? data.state : "";
      this.AddressObj.zip = data !== null ? data.zip : "";
      this.AddressObj.latitude = data !== null ? data.latitude : 0;
      this.AddressObj.longitude = data !== null ? data.longitude : 0;
      this.AddressObj.country = data !== null ? data.country : "";
      this.AddressObj.directionNote = data !== null ? data.directionNote : "";
      this.AddressObj.houseNo = data !== null ? data.houseNo : "";
      this.AddressObj.floorNumber = data !== null ? data.floorNumber : "";
      this.AddressObj.buildingName = data !== null ? data.buildingName : "";
      this.$emit('setSelectedAddress', this.AddressObj)
    },
    checkboxCheck() {
        this.addressList.forEach(e => {
            e.selected = false
        });
      this.isOfficeAddressSel = true;
      this.isAddedNewAddress = false;
      this.addedAddress = null
      this.errObj.addressErr = "";
      this.jobDisplayAdd = "";
      this.AddressObj.isAddressAddToCustomer = false;
      this.AddressObj.fullAddress = "";
      this.AddressObj.addressTitle = "";
      this.AddressObj.addressLine1 = "";
      this.AddressObj.addressLine2 = "";
      this.AddressObj.city = "";
      this.AddressObj.state = "";
      this.AddressObj.zip = "";
      this.AddressObj.latitude = 0;
      this.AddressObj.longitude = 0;
      this.AddressObj.country = "";
      this.AddressObj.directionNote = "";
      this.AddressObj.houseNo = "";
      this.AddressObj.floorNumber = "";
      this.AddressObj.buildingName = "";
      if (!this.isOfficeAddressSel) {
        this.getCustDetails(this.AddressObj.customerId);
      }
        
      this.$emit('setSelectedAddress', null)
    },
    setCustomerDetail() {
      let data = null
      let index = null
          this.addressList = []
          if (this.custDetail.addressList !== null) {
            this.showAddSelectAddr = true
            this.custDetail.addressList.forEach((e,i) => {
              let temp = 0
              let temp3 = ''
              if (this.LoginUserlatLong !== null) {
                temp = this.calcDistance(this.LoginUserlatLong.latitude, this.LoginUserlatLong.longitude, e.latitude, e.longitude)  
                temp3 = ` from user`
              } else {
                temp = this.calcDistance(this.OfficelatLong.latitude, this.OfficelatLong.longitude, e.latitude, e.longitude)
                temp3 = ` from office`
              }
              
              let value = this.getDistanceUnit(temp * 1000 )

              if (this.lat !== 0 && this.long !== 0) {
                if (e.latitude === this.lat && e.longitude === this.long) {
                  this.addressList.push({...e, selected: true, displayDistance: value  + temp3,  addressText : `(${e.addressTitle === '' ? 'Other' : e.addressTitle}) -` + ` ${e.fullAddress}`})  
                  data = e
                  index = i
                } else {
                  this.addressList.push({...e, selected: false, displayDistance: value  + temp3,  addressText : `(${e.addressTitle === '' ? 'Other' : e.addressTitle}) -` + ` ${e.fullAddress}`})
                }
              } else {
                this.addressList.push({...e, selected: false, displayDistance: value  + temp3,  addressText : `(${e.addressTitle === '' ? 'Other' : e.addressTitle}) -` + ` ${e.fullAddress}`})
              }
            });
          }
          setTimeout(() => {
            console.log('data && indexdata && indexdata && indexdata && index', data , 'dddd', index);
            
            if (data !== null && index !== null) {
              this.selectJobAddress(data, index)
            } else if (this.lat === 0 && this.long === 0) {
              this.checkboxCheck()
            } else {
              this.setAddress(this.selectedAdrObj, false);
              this.isAddedNewAddress = true
              this.isOfficeAddressSel = false
            }
          }, 2000);
    },
    getCustDetails(custId) {
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.custDetail = response.Data;
          this.setCustomerDetail()
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    calculateDistance (lat1, lon1, lat2, lon2, unit) {
      if (lat1 === lat2 && lon1 === lon2) {
        return 0;
      }
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist *= 1.609344;
      }
      if (unit === 'N') {
        dist *= 0.8684;
      }
      return dist;
    },
    getDistanceUnit (distanceMeter) {
      let distance = '';
      let countryCode;
      const moment = require('moment-timezone');
      const countryTimeZone = moment.tz.guess();  // Guesses the user's time zone
      timeZoneWithCountryCode.map(itm => {
        const ind = itm.timezones.findIndex(x => x.name === countryTimeZone);
        if (ind >= 0) {
          countryCode = itm.code;
        }
      });
      const index = milesCountry.findIndex(x => x.countryCode === countryCode);
      if (index >= 0) {
        if (distanceMeter * 0.00062137 >= 1000) {
          distance = `999+ mi`;
        } else if (distanceMeter * 0.00062137 < 0.1) {
          distance = '';
        } else {
          distance = `${(distanceMeter * 0.00062137).toFixed(1)} mi`;
        }
      } else if (distanceMeter / 1000 >= 1000) {
        distance = `999+ km`;
      } else if (distanceMeter / 1000 < 0.1) {
        distance = '';
      } else {
        distance = `${(distanceMeter / 1000).toFixed(1)} km`;
      }
      return distance;
    },
    calcDistance(lat1, lon1, lat2, lon2) {
      const radius = 6371; // Earth's radius in km
      const dLat = this.toRadians(lat2 - lat1);
      const dLng = this.toRadians(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRadians(lat1)) * Math.cos(this.toRadians(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;
      return distance.toFixed(2);
    },
    toRadians(degree) {
      return degree * (Math.PI / 180);
    },
  },
};
</script>
<style scoped>
</style>