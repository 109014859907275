<template>
  <div class="">
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="col_name_class w-32 uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
            </div>
            <div class="col_name_class w-48 uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            </div>
            <div class="col_name_class w-48 uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" >
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            </div>
          </div>
          <div v-if="list.length > 0">
            <div v-for="(data, index) in list" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="goToDetail(data.jobId)">
              <div class="col_name_class w-32">
                <div class="flex items-center">
                  <span class="text-gray4 heading-6"># {{data.jobNumber}}</span>
                </div>
              </div>
              <div class="col_name_class w-48">
                <div class="flex items-center">
                <span class="text-gray4 heading-6">{{data.jobTitle}}</span>
                </div>
              </div>
              <div class="col_name_class w-48">
                <div class="flex items-center">
                <span class="text-gray4 heading-6">{{data.nextVisitDateTime | formatDataeTime}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
        </div>
      </div>
     </div>
</template>
<script>
// import Button from '@/View/components/globalButton.vue'
// import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
// import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
export default {
  name: "joblist",
  components: {
    NoRecords,
    // descIconSvg,
    // ascIconSvg,
    // Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Number', icon: '', sortName: ''},
        {title: 'Title', icon: '', sortName: ''},
        {title: 'Next visit Scheduled', icon: '', sortName: ''}
      ],
    };
  },
  props: ['list'],
  created() {},
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    addNewQuote () {
    },
    goToDetail (Id) {
      this.$router.push({name: 'jobDetail', params: {jobId: Id}})
    }
  }
};
</script>
<style scoped>
.col_name_class {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>