<template>
    <div class="popup_overlay">
      <div class="custom_dialog bg-screen_bg" style="width: 100%; overflow: auto; background: #f2f3f6; min-height: 100%; max-height: 100%; margin: 0px !important">
        <div class="bg-primary p-3">
            <div class=" text-center font-medium text-white heading-3">
            Cerate Followup Task
            </div>
        </div>
        <div class="flex justify-center" >
          <div class=" cust_card bg-white  p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
            <div class="relative">
                <div>
                    <div class="p-4 sm:grid grid-cols-2 gap-4">
                        <div class="mb-4 sm:mb-0 w-full">
                        <TextField :isPointer="true" :placholderText="`Task Date`" :setReadOnly="true" :autoFocus="false" :inputext="reqObj.taskDate" :showDate="true" :lableText="'Task Date'" :inputId="'startimedkid'" @onClickInputAction="showDateSelect = true" />
                        <div v-if="showDateSelect" class="popup_overlay">
                        <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                            <DateTimePickerCustom
                                v-model="reqObj.taskDate"
                                v-if="showDateSelect"
                                :showDate="true"
                                :title="'Task Date'"
                                :showOnlyDate="true"
                                :showStartDate="true"
                                :projectSection="true"
                                :min="reqObj.taskDate"
                                :minDate="reqObj.taskDate" 
                                @closeDateTimepickerDialog="showDateSelect = false">
                            </DateTimePickerCustom>
                        </div>
                        </div>
                    </div>
                        <div class="mb-4 sm:mb-0 col-span-2">
                            <div class=" w-full sm:flex gap-4">
                                <div class="mb-4 sm:mb-0">
                                <TimePicker :title="'Start Time'" v-model="startTime"/>
                                </div>
                                <div class="">
                                <TimePicker :title="'End Time'" v-model="endTime"/>
                                <p v-if="timeErr" class="text-error mb-2">End time should be greater than Start time</p>
                                </div>
                            </div>
                        </div>
                        <div class="mb-4 sm:mb-0 col-span-2">
                            <CustomerAddressSelection @setSelectedAddress="setSelectedAddress" :customerId="reqDetail.customerId" />
                        </div>
                        <div class="mb-4 sm:mb-0">
                            <Dropdown 
                                :inputext="selectReminderVal" 
                                :inputId="'ReminderValue'"
                                :lableText="'Team Reminder'"
                                :placholderText="`Select Reminder`"
                                :autoFocus="false"
                                :showArrow="true"
                                :setReadOnly="true"
                                :searchCallApi="false"
                                :showPlus="false"
                                :keyName="'title'"
                                :listId="'remId'"
                                :items="reminderList"
                                @selectNewForVal="selectReminder"
                                />
                        </div>
                        <div class="mb-4 sm:mb-0">
                            <MultiSelect 
                            :inputext="employeeSearchText" 
                            :inputId="'rsequestassignemp'"
                            :lableText="'Assign to'"
                            :placholderText="`Select Employee`"
                            :autoFocus="false"
                            :showArrow="true"
                            :setReadOnly="false"
                            :searchCallApi="false"
                            :showPlus="false"
                            :keyName="'fullName'"
                            :idKey="'userDetailId'"
                            :sectionId="'requesectionlistid'"
                            :listId="'empreqlistid'"
                            :items="employeeList"
                            :selectedValues="selectedEmployee"
                            @selectNewForVal="selectEmployee"
                            />
                            <p v-if="assigneeErr !== ''" class="text-error mb-2">{{assigneeErr}}</p>
                        </div>
                        <div class="mb-4 col-span-2">
                            <TextAreaInput 
                        :inputId="'discriptioncon'"
                        :inputext="reqObj.instructions"
                        :placholderText="`Write description here...`"
                        :lableText="'Description'"
                        :inputType="'text'"
                        :autoFocus="false"
                        :cols="50"
                        :rows="3"
                        :setReadOnly="false"
                        @inputChangeAction="(data) => reqObj.instructions = data"  />
                        </div>
                        
                    </div>
                </div>
                <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
                    <div class="text-rigth flex gap-2 mb-3">
                        <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="discard()"/>
                        <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveTaskDetail()"/>
                    </div>
                </div>
        </div>
    </div>
    </div>
    </div>
  </div>
</template>
<script>
// import ConfirmBox from '@/View/components/ConfirmBox.vue'
// import StarRating from 'vue-star-rating'
import TextField from '@/View/components/textfield.vue'
// import NumberInput from '@/View/components/number-text-field.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
// import TimePicker from '../../job/components/timePicker.vue'
import MultiSelect from '@/View/components/multipleSelectDrop.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
import moment from 'moment'
import TimePicker from '@/View/job/components/timePicker.vue'
import Dropdown from '@/View/components/dropdown.vue'
import CustomerAddressSelection from './customerAddressSelection.vue'
export default {
  name: 'addupdateshcedule',
  components: {
    TextField,
    DateTimePickerCustom,
    Button,
    MultiSelect,
    TimePicker,
    Dropdown,
    TextAreaInput,
    CustomerAddressSelection,
  },
  data () {
    return {
        reminderList: [
          {remId: 0, title: 'No reminder set', value: -1},
          {remId: 1, title: 'At start of task', value: 0},
          {remId: 2, title: '5 min prior', value: 5},
          {remId: 3, title: '10 min prior', value: 10},
          {remId: 4, title: '15 min prior', value: 15},
          {remId: 5, title: '30 min prior', value: 30},
          {remId: 6, title: '1 hour prior', value: 60},
          {remId: 7, title: '2 hours prior', value: 120},
          {remId: 8, title: '5 hours prior', value: 300},
          {remId: 9, title: '12 hours prior', value: 720},
          {remId: 10, title: '24 hours prior', value: 1440}
        ],
        selectReminderVal: 'No reminder set',
        employeeSearchText: '',
        logedInUser: 0,
        employeeList: [],
        selectedEmployee: [],
        showDateSelect: false,
        timeErr: false,
        assigneeErr: '',
        startTime: "",
        endTime: "",
        reqObj: {
        requestId: 0,
        instructions: '',
        startTime: '',
        endTime: '',
        assignToList: [],
        reminderBeforeMinutes: 0,
        taskDate: '',
        taskId: 0,
        taskAddressObj: null,
        
    },
    }
  },
  props: ['requestId', 'reqDetail'],
   created() {
  },
  beforeMount () {
      this.setHourTime()
      this.getEmployeeList()
  },
  mounted () {
    document.body.style = 'overflow: hidden;'
    let detailObj = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.logedInUser = detailObj.userDetailId
    this.reqObj.taskDate = moment(new Date()).format('YYYY-MM-DD')
    this.reqObj.requestId = this.reqDetail.requestId
  },
  watch: {
    startTime: {
      handler () {
        this.timeErr = false
           let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
      }
    },
    endTime: {
      handler () {
        this.timeErr = false
        let x = this.startTime.split(':')
            let hour = parseInt(x[0])
            let min = parseInt(x[1])
           let y = this.endTime.split(':')
            let hr2 = parseInt(y[0])
            let min2 = parseInt(y[1])
        if (hour > hr2) {
          this.timeErr = true
        } else if (hour === hr2 && (min > min2 || min === min2) ) {
          this.timeErr = true
        }
      }
    }
  },
  methods: {
    setSelectedAddress(addresObj) {
        if (addresObj !== null) {
            this.reqObj.taskAddressObj = {
                addressLine1: addresObj.addressLine1 ? addresObj.addressLine1 : '',
                addressLine2: addresObj.addressLine2 ? addresObj.addressLine2 : '',
                city: addresObj.city ? addresObj.city : '',
                state: addresObj.state ? addresObj.state : '',
                zip: addresObj.zip ? addresObj.zip : '',
                country: addresObj.country ? addresObj.country : '',
                latitude: addresObj.latitude ? addresObj.latitude : 0,
                longitude: addresObj.longitude ? addresObj.longitude : 0,
                geofenceRangeDiameter: addresObj.geofenceRangeDiameter ? addresObj.geofenceRangeDiameter : 0,
                fullAddress: addresObj.fullAddress ? addresObj.fullAddress : '',
                houseNo: addresObj.houseNo ? addresObj.houseNo : '',
                floorNumber: addresObj.floorNumber ? addresObj.floorNumber : '',
                buildingName: addresObj.buildingName ? addresObj.buildingName : '',
                directionNote: addresObj.directionNote ? addresObj.directionNote : '',
                contactNumber: addresObj.contactNumber ? addresObj.contactNumber : '',
                contactName: addresObj.contactName ? addresObj.contactName : '',
                addressTitle: addresObj.addressTitle ? addresObj.addressTitle : '',
                customerId: addresObj.customerId ? addresObj.customerId : 0,
                isAddressAddToCustomer: addresObj.isAddressAddToCustomer ? addresObj.isAddressAddToCustomer : false,
            }
        } else {
            this.reqObj.taskAddressObj = null
        }
    },
    selectReminder (data) {
      console.log('data', data)
      this.selectReminderVal = data !== null ? data.title : 'No reminder set'
      this.reqObj.reminderBeforeMinutes = data !== null ? data.value : 0
      this.$root.$emit('setReminderVal', data)
      // this.$root.$emit('setSheduleDataEmpHandler',{repeatedType: this.repeatViType, repeatedDayOrDate: data.val})
    },
    setHourTime () {
      let hours = new Date().getHours()
      let min = new Date().getMinutes()
      hours = min > 45 &&  min < 60 ? hours + 1 : hours
      let minutes = min <= 15 ? 15 : min <= 30 ? 30 : min <= 45 ? 45 : min <= 60 ? 0 : 0
      
      this.startTime = hours + ':' + minutes
      this.endTime = hours + 1 + ':' + minutes
      console.log('123 call, 1', this.startTime)
      console.log('123 call, 1', this.endTime)
    },
    selectEmployee (emplist) {
        this.assigneeErr = ''
      this.selectedEmployee = []
      let tempEmpList = []
      emplist.map((data) => {
        tempEmpList.push({userDetailId:data.userDetailId})
        this.selectedEmployee.push(data)
      })
      this.reqObj.assignToList = tempEmpList
    },
    discard () {
      document.body.style = 'overflow: auto;'
      this.$root.$emit('followupTaskHandler', false)
    },
    saveTaskDetail () {

        let isValid = true
        if (this.timeErr) {
            isValid = false
        }

        if (this.reqObj.assignToList.length === 0) {
            this.assigneeErr = 'Please select atleast one assignee'
             isValid = false
        }

        if (isValid) {
            let strDate = moment(new Date(this.reqObj.taskDate)).format('YYYY-MM-DD') + ' ' + this.startTime
            let endDate = moment(new Date(this.reqObj.taskDate)).format('YYYY-MM-DD') + ' ' + this.endTime
            this.reqObj.startTime = new Date(strDate).toUTCString(),
            this.reqObj.endTime = new Date(endDate).toUTCString()
            if (this.reqObj.taskAddressObj !== null) {
                this.reqObj.taskAddressObj.customerId = this.reqObj.taskAddressObj.isAddressAddToCustomer ? this.reqDetail.customerId : 0
            }
            this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
            MyJobApp.reqAddStepThree(
                this.reqObj,
                response => {
                    document.body.style = 'overflow: auto;'
                    this.$root.$emit('followupTaskHandler', true)           
                    this.$router.push({name: 'TaskDetail', params: {taskId: response.TaskId}, query: {date: this.reqObj.taskDate}})
                    this.$store.dispatch('SetLoader', {status: false, message: ''})
                },
                () => {
                this.$store.dispatch('SetLoader', {status: false, message: ''})
                }
            )
        }
    },
    getEmployeeList () {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.getEmployeeList(
        'active',
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
            response.Data.tableRow.map(el => {
               tempList.push({...el, fullName: el.firstName + ' ' + el.lastName})
               if (this.logedInUser === el.userDetailId && this.selectedEmployee.length === 0) {
              this.selectedEmployee.push({...el, fullName: el.firstName + ' ' + el.lastName})
              this.reqObj.assignToList.push({
                userDetailId: el.userDetailId
              })
            }
            })
          }
          this.employeeList = tempList
          
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    getCustDetails(custId) {
      MyJobApp.getCustomerDetail(
        custId,
        (response) => {
          this.custDetail = response.Data;
          this.setCustomerDetail()
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
  },
  beforeDestroy () {
    this.$root.$off("confirmBoxHandler");
  }
}
</script>
<style scoped>

</style>